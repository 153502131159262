<template>
  <div class="home mt-3">
    <v-row>
      <v-col cols="12" lg="6">
        <tabla :header="headers" :body="activosVencidos" @recargar="listarItem" tituloTabla="Últimos Ítems Vencidos" />
      </v-col>
      <v-col cols="12" lg="6">
        <tabla :header="headers" :body="activos" @recargar="listarItem" tituloTabla="Ítems por Vencer" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import {
    mapState,
    mapActions
  } from "vuex";
  import tabla from "../components/view/Tabla";
  export default {
    name: "Inicio",
    components: {
      tabla
    },
    data: () => ({
      url_api: process.env.VUE_APP_URL_API,
      headers: [{
          text: '#',
          value: 'numero'
        },
        {
          text: 'Codigo',
          align: 'start',
          value: 'codigo'
        },
        {
          text: 'Nombre',
          align: 'start',
          value: 'descripcion'
        },
      ],

      activosVencidos: [],
      activos: [],
    }),

    computed: {
      ...mapState(['token']),
      formTitle() {
        return this.editedIndex === -1 ? "New Item" : "Edit Item";
      },
    },

    watch: {
      dialog(val) {
        val || this.close();
      },
      dialogDelete(val) {
        val || this.closeDelete();
      },
    },

    created() {
      // this.initialize();
      this.listarItem();
    },

    methods: {
      ...mapActions(['cerrarSesion']),
      async listarItem() {
        try {
          await fetch(`${this.url_api}/item`, {
              headers: {
                'Content-Type': 'application/json',
                "auth-token": this.token
              }
            }).then(res => res.json())
            .catch(error => console.error('Error:', error))
            .then(response => {
              if (response.body) {
                
                var fechaActual = (new Date(Date.now() - (new Date()).getTimezoneOffset() *
                  60000)).toISOString().substr(0, 10);
                // alert(fechaActual)
                var fechaActua = fechaActual.match(/(\d+)/g);

                response.body.forEach(element => {
                  var fechaVence = element.vencimiento;
                  var fechaVen = fechaVence.match(/(\d+)/g);
                  if ((new Date(fechaActua[0], fechaActua[1] - 1, fechaActua[2])
                      .getTime()) > (
                      new Date(fechaVen[0], fechaVen[1] - 1, fechaVen[2])
                      .getTime()
                    )) {
                    this.activosVencidos.push(element);
                    
                  }else{
                    this.activos.push(element);
                  }
                })
                // this.total = this.activos.length
                // this.proximoVence = this.total - this.vencidos;
              } else if (response.error == ('!token' || 'tokenNull')) {
                this.cerrarSesion();
              }
            });
        } catch (error) {
          console.error('Error:', error);
        }
      },
    },

  };
</script>